import React from 'react';

import Layout from '../components/Layout';
import Seo from '../components/Seo';


const NotFoundPage = () => (
    <Layout>
        <Seo title="Page not found"/>
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn't exist... the sadness.</p>
    </Layout>
);

export default NotFoundPage;
